<template>
  <view class="reader-box" v-if="isScaning">
    <QrcodeStream @detect="onDetect" @init="onInit"  @camera-on="onCameraOn">
      <div class="qr-scanner" v-if="isCamera">
        <div class="box"  style="height: 95%; width: 95%;">
          <div class="line"></div>
          <div class="angle"></div>
        </div>
      </div>
    </QrcodeStream>
  </view>
  <div class="QR-view" @click="scanCode">
    <div class="QR-content">
      <img src="@/assets/QR.png" style="width: 80%; height: 80%" />
    </div>
    <div class="qr-scanner" v-if="!isCamera">
      <div class="box">
        <div class="line"></div>
        <div class="angle"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { showToast } from "vant";
import { QrcodeStream } from "vue-qrcode-reader";
let isScaning = ref(false);
let isCamera = ref(false);
// 打开扫码
function scanCode() {
  isScaning.value = true;
}
function onCameraOn(){
  isCamera.value = true;
}

async function  onInit (promise)  {
  try {
        await promise
      } catch (error) {
        console.log('-------');
        console.log(error);
        console.log('-------');
        if (error.name === 'NotAllowedError') {
          // ERROR: you need to grant camera access permission
          // showToast("您需要授予摄像机访问权限")
     
        } else if (error.name === 'NotFoundError') {
          // ERROR: no camera on this device
          showToast("这个设备上没有摄像头")
        } else if (error.name === 'NotSupportedError') {
          // ERROR: secure context required (HTTPS, localhost)
          showToast("需要安全上下文(HTTPS, localhost)")
        } else if (error.name === 'NotReadableError') {
          // ERROR: is the camera already in use?
          showToast("摄像机已经在使用了吗?")
        } else if (error.name === 'OverconstrainedError') {
          // ERROR: installed cameras are not suitable
          showToast("安装的摄像头不合适")
        } else if (error.name === 'StreamApiNotSupportedError') {
          showToast("此浏览器不支持流API");
        } else if (error.name === 'InsecureContextError') {
          showToast(`摄像头只能在安全环境下使用。使用HTTPS或本地主机，而不是HTTP`);
        } else {
          showToast('`ERROR: Camera error (${error.name})`');
        }
      }

}



// 扫码回调
function onDetect(detectedCodes) {
  const target = detectedCodes[0];
  if (target && target.rawValue) {
    showToast(target.rawValue);
    isScaning.value = false;
    isCamera.value = false;
  } else {
    isScaning.value = false;
    isCamera.value = false;
    showToast("失败");
  }
}
</script>

<style lang="less" scoped>
.reader-box {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 100;
}
.QR-view {
  margin-top: 25px;
  width: 93px;
  height: 93px;
  position: relative;

  .QR-content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.qr-scanner {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10000;
}

.qr-scanner .box {
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  border: 0.1rem solid rgba(0, 255, 51, 0.2);
}

.qr-scanner .line {
  height: calc(100% - 2px);
  width: 100%;
  background: linear-gradient(180deg, rgba(0, 255, 51, 0) 43%, #00ff33 211%);
  border-bottom: 3px solid #00ff33;
  transform: translateY(-100%);
  animation: radar-beam 2s infinite;
  animation-timing-function: cubic-bezier(0.53, 0, 0.43, 0.99);
  animation-delay: 1.4s;
}

.qr-scanner .box:after,
.qr-scanner .box:before,
.qr-scanner .angle:after,
.qr-scanner .angle:before {
  content: "";
  display: block;
  position: absolute;
  width: 3vw;
  height: 3vw;

  border: 0.2rem solid transparent;
}

.qr-scanner .box:after,
.qr-scanner .box:before {
  top: 0;
  border-top-color: #00ff33;
}

.qr-scanner .angle:after,
.qr-scanner .angle:before {
  bottom: 0;
  border-bottom-color: #00ff33;
}

.qr-scanner .box:before,
.qr-scanner .angle:before {
  left: 0;
  border-left-color: #00ff33;
}

.qr-scanner .box:after,
.qr-scanner .angle:after {
  right: 0;
  border-right-color: #00ff33;
}

@keyframes radar-beam {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}
</style>