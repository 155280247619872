import { createApp } from 'vue'
import App from './App.vue'
import { Button ,NavBar,Image as VanImage,Toast  } from 'vant';
import "vant/lib/index.css"
const app = createApp(App)

app.use(Button)
app.use(NavBar)
app.use(VanImage)
app.use(Toast)

app.mount('#app')

