<template>
  <div class="page-view">
    <img src="@/assets/bg.png" style="width: 100%; height: 100%" />
    <div class="content-view">
      <ScanQR></ScanQR>
      <div class="prompt-view">
        <div class="prompt-title">石家庄市合规网约车识别码验证1</div>
        <div class="prompt-content" style="margin-top: 8px">
          点击上方二维码图片，扫码二维码查验
        </div>
        <div class="prompt-content">石家庄市网约车合规信息</div>
      </div>
      <div class="car-message">
        <img
          src="../assets/normal.png"
          style="
            position: absolute;
            bottom: 5px;
            right: 8px;
            height: 74px;
            width: 74px;
          "
        />
        <div class="message-item" v-for="item in list" :key="item.left">
          <div style="flex-shrink: 0">{{ item.left }}</div>
          <div>{{ item.right }}</div>
        </div>
      </div>
      <div class="bottom-view">
        <div style="">数据来源：石家庄市交通运输综合行政执法支队</div>
        <div>技术支持：河北润络科技有限公司</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import ScanQR from "../components/ScanQR.vue";
let list = ref([
  { left: "证照名称：", right: "网络预约出租汽车运输证" },
  { left: "车牌号码：", right: "冀AD9234" },
  { left: "持证主体：", right: "石家庄冀小司服务有限公司" },
  { left: "有效期至：", right: "2027-01-22" },
  { left: "发证机关：", right: "石家庄交通运输局" },
  { left: "经营范围：", right: "网络预约出租汽车" },
  { left: "扫码时间：", right: "2024年05月08日16:24:02" },
]);



</script>

<style lang="less" scoped>
.page-view {
  width: 100%;
  height: 100%;
  position: relative;
  .content-view {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .prompt-view {
      background-color: #ffffff;
      border-radius: 10px;
      height: 88px;
      width: 100%;
      opacity: 0.5;
      margin-top: 21px;
      backdrop-filter: blur(10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      .prompt-title {
        margin-top: 12px;
        font-weight: 400;
        font-size: 16px;
        color: #112cb0;
        font-style: normal;
      }
      .prompt-content {
        font-size: 12px;
        color: #3a4044;
        font-style: normal;
      }
    }
    .car-message {
      margin-top: 10px;
      background: white;
      border-radius: 10px;
      opacity: 0.75;
      width: 100%;
      backdrop-filter: blur(10px);
      position: relative;

      .message-item {
        margin: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 46px;
      }
    }
    .bottom-view {
      margin-top: 25px;
      font-size: 12px;
      color: #3a4044;
      line-height: 17px;
    }
  }
}
</style>